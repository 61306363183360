<template>
    <div class="texno-khan-o">
        <div class="card-table-body ifon-add-title-block">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="caz-blocks-sarcho-title">
                        <div
                            class="content-title d-flex align-center mr-2"
                            :class="
                                mode
                                    ? 'content__titleday'
                                    : 'content__titlenight'
                            "
                        >
                            Товары
                        </div>
                        <div class="block-sarche">
                            <div class="header__search d-flex">
                                <crm-input
                                    :size="'small'"
                                    :className="'w100'"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    v-model="search"
                                    :icon="'el-icon-search'"
                                    class="mr-3"
                                    style="width: 220px"
                                ></crm-input>
                                <el-date-picker
                                    v-model="value1"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    size="small"
                                    type="date"
                                    class="mr-3"
                                    placeholder="От"
                                >
                                </el-date-picker>
                                <el-date-picker
                                    v-model="value1"
                                    size="small"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    type="date"
                                    placeholder="До"
                                >
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="24">
                    <el-breadcrumb separator="/">
                        <el-breadcrumb-item
                            class="text-white"
                            :to="{ path: '/' }"
                        >
                            Home
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: 'regionsProducts' }">
                            Регионы
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: 'regionDealer' }">
                            Ферганская область
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: 'dealer' }">
                            Ozodbek Umarov
                        </el-breadcrumb-item>
                        <el-breadcrumb-item> Магазин А </el-breadcrumb-item>
                    </el-breadcrumb>
                </el-col>
            </el-row>
        </div>
        <!-- end ifon-add-title-block -->

        <div class="card-table-header table-crm-smart mt-0 pt-0">
            <table
                class="table-my-code table-bordered mt-0 pt-0"
                :class="mode ? 'table__myday' : 'table__mynight'"
                v-loading="loadingData"
            >
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.napeProduct.show">
                            {{ columns.napeProduct.title }}
                        </th>

                        <th v-if="columns.gruppa.show">
                            {{ columns.gruppa.title }}
                        </th>

                        <th v-if="columns.serialNumber.show">
                            {{ columns.serialNumber.title }}
                        </th>

                        <th v-if="columns.clasE.show">
                            {{ columns.clasE.title }}
                        </th>

                        <th v-if="columns.Guarantees.show">
                            {{ columns.Guarantees.title }}
                        </th>

                        <th v-if="columns.dataContract.show">
                            {{ columns.dataContract.title }}
                        </th>

                        <th v-if="columns.data.show">
                            {{ columns.data.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.napeProduct.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.napeProduct"
                                :placeholder="columns.napeProduct.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.gruppa.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.gruppa"
                                :placeholder="columns.gruppa.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.serialNumber.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.serialNumber"
                                :placeholder="columns.serialNumber.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.clasE.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.clasE"
                                :placeholder="columns.clasE.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.Guarantees.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.Guarantees"
                                :placeholder="columns.Guarantees.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.dataContract.show">
                            <el-date-picker
                                :class="mode ? 'mode_1' : 'mode__2'"
                                :placeholder="columns.dataContract.title"
                                v-model="filterForm.dataContract"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th v-if="columns.data.show">
                            <el-date-picker
                                :class="mode ? 'mode_1' : 'mode__2'"
                                :placeholder="columns.data.title"
                                v-model="filterForm.data"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="user in list"
                        :key="user.id"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">{{ user.id }}</td>

                        <td v-if="columns.napeProduct.show">
                            {{ user.napeProduct }}
                        </td>

                        <td v-if="columns.gruppa.show">
                            {{ user.gruppa }}
                        </td>

                        <td v-if="columns.serialNumber.show">
                            {{ user.serialNumber }}
                        </td>

                        <td v-if="columns.serialNumber.show">
                            {{ user.serialNumber }}
                        </td>

                        <td v-if="columns.clasE.show">
                            {{ user.clasE }}
                        </td>

                        <td v-if="columns.Guarantees.show">
                            {{ user.Guarantees }}
                        </td>

                        <td v-if="columns.data.show">{{ user.data }}</td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <el-dropdown
                                :hide-on-click="false"
                                class="table-bottons-right"
                            >
                                <span class="el-dropdown-link more_icons">
                                    <i
                                        class="
                                            el-icon-arrow-down
                                            el-icon-more-outline
                                        "
                                    ></i>
                                </span>
                                <el-dropdown-menu
                                    slot="dropdown"
                                    class="dropdown-menumy-style"
                                >
                                    <el-dropdown-item icon="el-icon-edit">
                                        Изменить
                                    </el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-delete">
                                        Удалить
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </td>
                    </tr>
                </transition-group>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    name: "texnoProduct",
    data() {
        return {
            filterForm: {
                id: "",
                napeProduct: "",
                gruppa: "",
                serialNumber: "",
                clasE: "",
                Guarantees: "",
                dataContract: "",
                data: "",
            },
            list: [
                {
                    id: "1",
                    napeProduct: "Samsung RB30N4020B1/WT",
                    gruppa: "Холодильник",
                    serialNumber: "CXZC3454CX",
                    clasE: "A+",
                    Guarantees: "3 года",
                    dataContract: "02.05.1995",
                    data: "12.12.2020",
                },
            ],
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },

                napeProduct: {
                    show: true,
                    title: "Наименование",
                    sortable: true,
                    column: "napeProduct",
                },

                gruppa: {
                    show: true,
                    title: "Группа",
                    sortable: true,
                    column: "gruppa",
                },

                serialNumber: {
                    show: true,
                    title: "Серийный номер",
                    sortable: true,
                    column: "serialNumber",
                },

                clasE: {
                    show: true,
                    title: "Класс энергопотребления",
                    sortable: true,
                    column: "clasE",
                },

                Guarantees: {
                    show: true,
                    title: "Гарантия",
                    sortable: true,
                    column: "Guarantees",
                },

                dataContract: {
                    show: true,
                    title: "Дата создания ",
                    sortable: true,
                    column: "dataContract",
                },
                data: {
                    show: true,
                    title: "Изменено ",
                    sortable: true,
                    column: "data",
                },
                settings: {
                    show: true,
                    title: "Профиль",
                    sortable: false,
                    column: "settings",
                },
            },
        };
    },
};
</script>
